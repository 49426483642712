<template>
  <div class="index">
    <el-container>
        <el-header>
            <div class="lef">
                <!-- <img class="logo" src="@/assets/image/logo.png" alt=""> -->
                <div class="logo"></div>
                <span>通辽远程接访后台管理系统</span>
            </div>
            <div class="rig">
                <img class="profile" src="@/assets/image/ic_home_touxiang.png" alt="">
                <span>{{ $store.state.userinfo }}</span>
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ $store.state.username }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item command="gopassword">修改密码</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <!-- <span @click="gopassword">{{ $store.state.username }}</span> -->
                <div class="close" @click="close"></div>
            </div>
        </el-header>
        <el-container>
            <el-aside width="208px">
                <el-menu router
                :default-active="$route.path" 
                background-color="#D8E8FF" 
                text-color="#0066C8" 
                active-text-color="#fff" v-for="item in rolelist" :key="item.title">
                    <el-menu-item :index="item.path" v-if="item.children == null">
                        <img v-if="item.meta.title =='控制台'" src="../assets/image/ic_home.png" class="two" alt="" />
                        <span slot="title">{{item.meta.title}}</span>
                    </el-menu-item>
                    <el-submenu :index="item.path" v-else>
                        <template slot="title"><img v-if="item.meta.title =='远程接访'" src="../assets/image/ic_home_leftbar.png" class="two" alt="" />
                        <img v-if="item.meta.title =='检察院用户管理'" src="../assets/image/users.png" class="two" alt=""
                        />
                        <img v-if="item.meta.title =='资讯管理'" src="../assets/image/information.png" class="two" alt=""
                        />
                        <img v-if="item.meta.title =='统计查询'" src="../assets/image/statistics.png" class="two" alt=""
                        />
                        <img v-if="item.meta.title =='系统管理'" src="../assets/image/system.png" class="two" alt=""
                        />{{item.meta.title}}</template>
                        <el-menu-item class="child" :index="val.path" v-for="val in item.children" :key="val.title">
                        <ul><li>{{val.meta.title}}</li></ul>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view  v-if="isRouterAlive"></router-view>
            </el-main>
        </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
    provide(){
      return{
        reload:this.reload
      }
    },
   data(){
    return{
        rolelist:[],
        isRouterAlive:true
    }
   },
   methods:{
    close(){
        localStorage.removeItem("token");
        this.$router.push({ path: '/login' })
    },
    handleCommand(command){
        if (command=="gopassword"){
            this.$router.push({ path: '/index/password' })
        }
    },
    // gopassword(){
    //     this.$router.push({ path: '/index/password' })
    // },
    reload(){
        this.isRouterAlive = false;
        this.$nextTick(function () {
          this.isRouterAlive = true;
        });
      }
   },
   mounted() {
    // console.log( '路由', this.$store.getters.get_routes );
    this.rolelist = this.$store.getters.get_routes

   }
}
</script>


<style  lang="less" scoped>
.el-dropdown-link {
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}
.el-icon-arrow-down {
    font-size: 18px;
    font-weight: 400;
}
::v-deep .el-dropdown-menu__item{
    list-style:none !important;
}
.index{
    width: 100vw;
    height: 100vh;
    background: #f8f6f6;
}
.el-container{
    height: 100%;
}
.el-header{
    height: 72px !important;
    background: #56AAF7;
    box-shadow: 0px 1px 4px 0px rgba(0,21,41,0.1200);
    display: flex;
    justify-content: space-between;
    .lef,.rig{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .lef{
        font-size: 28px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        .logo{
            width: 48px;
            height: 48px;
            margin-right: 24px;
            background: url(~@/assets/image/logo.png) no-repeat center center;
            background-size: 100%;
        }
    }
    .rig{
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 25px;
        span{
            margin-left: 16px;
        }
        .profile{
            width: 36px;
            height: 36px;
        }
        .close{
            width: 30px;
            height: 30px;
            background: url(~@/assets/image/ic_home_nav_close.png) no-repeat center center;
            background-size: 100%;
            margin-left: 20px;
        }
    }
}
.is-vertical .el-container{
    height: calc(100% - 72px);
}
::-webkit-scrollbar {
        display: none; /* Chrome Safari */
}

.el-aside{
    height: calc(100vh - 72px);
    background: #D8E8FF;
    box-shadow: 2px 0px 6px 0px rgba(0,21,41,0.1200);
    scrollbar-width: none;
    -ms-overflow-style: none;
    .one,.two{
        display: inline-block;
        width: 20px;
        height: 20px;
    }
    // .one{
    //     background: url(~@/assets/image/ic_home.png) no-repeat center center;
    // }
    // .two{
    //     background: url(~@/assets/image/ic_home_leftbar.png) no-repeat center center;
    // }
    .el-menu-item.is-active {
        background-color: #7BC0FF !important;
        // .one{
        //     background: url(~@/assets/image/ic_home_leftbar_shouye_selected.png) no-repeat center center;
        // }
        // .two{
        //     background: url(~@/assets/image/ic_home_leftbar_xitongguanli_unselect.png) no-repeat center center;
        // }
        
    }
    .child{
        padding-left: 60px !important;
    }
    
}
::v-deep .el-submenu__title:hover{
 background-color: #a6d4ff !important;
}
::v-deep .el-menu-item:hover{
 background-color: #a6d4ff !important;
}
li {
    list-style-type:disc;
}
</style>